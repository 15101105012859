exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-layout-portfolio-jsx": () => import("./../../../src/layouts/LayoutPortfolio.jsx" /* webpackChunkName: "component---src-layouts-layout-portfolio-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-en-about-jsx": () => import("./../../../src/pages/en/about.jsx" /* webpackChunkName: "component---src-pages-en-about-jsx" */),
  "component---src-pages-en-contact-jsx": () => import("./../../../src/pages/en/contact.jsx" /* webpackChunkName: "component---src-pages-en-contact-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-portfolio-jsx": () => import("./../../../src/pages/en/portfolio.jsx" /* webpackChunkName: "component---src-pages-en-portfolio-jsx" */),
  "component---src-pages-en-snippets-jsx": () => import("./../../../src/pages/en/snippets.jsx" /* webpackChunkName: "component---src-pages-en-snippets-jsx" */),
  "component---src-pages-en-success-jsx": () => import("./../../../src/pages/en/success.jsx" /* webpackChunkName: "component---src-pages-en-success-jsx" */),
  "component---src-pages-es-404-es-jsx": () => import("./../../../src/pages/es/404.es.jsx" /* webpackChunkName: "component---src-pages-es-404-es-jsx" */),
  "component---src-pages-es-contacto-es-jsx": () => import("./../../../src/pages/es/contacto.es.jsx" /* webpackChunkName: "component---src-pages-es-contacto-es-jsx" */),
  "component---src-pages-es-index-es-jsx": () => import("./../../../src/pages/es/index.es.jsx" /* webpackChunkName: "component---src-pages-es-index-es-jsx" */),
  "component---src-pages-es-portafolio-es-jsx": () => import("./../../../src/pages/es/portafolio.es.jsx" /* webpackChunkName: "component---src-pages-es-portafolio-es-jsx" */),
  "component---src-pages-es-snippets-es-jsx": () => import("./../../../src/pages/es/snippets.es.jsx" /* webpackChunkName: "component---src-pages-es-snippets-es-jsx" */),
  "component---src-pages-es-sobre-mi-es-jsx": () => import("./../../../src/pages/es/sobre-mi.es.jsx" /* webpackChunkName: "component---src-pages-es-sobre-mi-es-jsx" */),
  "component---src-pages-es-success-es-jsx": () => import("./../../../src/pages/es/success.es.jsx" /* webpackChunkName: "component---src-pages-es-success-es-jsx" */)
}

